<template>
  <section id="services">
    <v-img
      :src="require('@/assets/background/2.png')"
    >
      <base-section-heading
        title="Our Services"
        class="mt-12"
      >
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga. -->
      </base-section-heading>

      <v-container>
        <v-row>
          <v-col
            v-for="(feature, i) in features"
            :key="i"
            cols="12"
            md="6"
          >
            <!-- <base-avatar-card
              color="white"
              v-bind="feature"
              align="left"
              horizontal
            >
              {{ feature.desc }}
            </base-avatar-card> -->

            <v-row
              justify="center"
              align="center"
            >
              <v-col
                cols="3"
                md="2"
              >
                <v-icon
                  size="80"
                  color="secondary"
                >
                  {{ feature.icon }}
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                md="10"
              >
                <p
                  class="text-h6"
                >
                  {{ feature.title }}
                </p>
                <p
                  class="text-body-1"
                >
                  {{ feature.desc }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionOurServices',

    data: () => ({
      features: [
        {
          title: 'GNSS Data Processing',
          icon: 'mdi-satellite-variant',
          desc: 'Using the Bernese GNSS Software we provide independent scientific-grade solutions for a wide range of positioning (terrestrial and space), timing, and meteorological applications.',
          outlined: false,
        },
        {
          title: 'Continuous Monitoring',
          icon: 'mdi-radar',
          desc: 'Our cloud-based GNSS monitoring service provides high quality automated monitoring that is rapidly scalable, eliminates infrastructure expenditure, and requires little or no knowledge of scientific GNSS processing principles.',
          outlined: false,
        },
        {
          title: 'Network Adjustment',
          icon: 'mdi-webhook',
          desc: 'Our cloud-based survey network adjustment service (DynaWeb) provides you with an intuitive, easy-to-use, and familiar interface to the world-leading DynAdjust software.',
          outlined: false,
        },
        {
          title: 'Bernese Training',
          // icon: 'mdi-lead-pencil',
          icon: '$vuetify.icons.bernese',
          desc: 'Interested in learning Bernese? Keen to automate your GPS/GNSS data processing with the Bernese Processing Engine (BPE)? Position++ provides customised “hands-on” training where you learn Bernese using your data and infrastructure.',
          outlined: false,
        },
        {
          title: 'Education',
          icon: 'mdi-school',
          desc: 'There’s only one thing better than being a GNSS expert, and that’s helping others to build the knowledge, understanding, and skills necessary to become one.',
          outlined: false,
        },
        {
          title: 'Consulting',
          icon: 'mdi-lightbulb-on-outline',
          desc: 'We provide strategic, commercial, and technical insights and advice on a diverse range of topics including GNSS infrastructure, establishing and maintenaining reference frames, distortion modelling, and capacity building.',
          outlined: false,
        },
      ],
    }),

    computed: {
      maxHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? '900' : '1800'
      },
    },
  }
</script>
